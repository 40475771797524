import {ChangeDetectionStrategy, Component, EventEmitter, Output, input} from '@angular/core';
import {RequestDto} from 'projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {FetchResourceAction, RequestFormAction} from 'projects/admin-query/src/app/models/request-form-actions.model';

@Component({
  selector: 'app-step-edit-request',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
  standalone: true
})
export abstract class StepEditRequestComponent {
  public readonly request = input.required<RequestDto>();
  public readonly hasUnsavedChanges = input(false);

  @Output()
  public back = new EventEmitter<void>();

  @Output()
  public action = new EventEmitter<RequestFormAction>();

  @Output()
  public fetchResource = new EventEmitter<FetchResourceAction>();
}
